<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import { FilterStore } from '$lib/utils/filters/store';
  import { isMobileBrowser } from '$lib/utils/browser-utils';
	import { goto } from '$app/navigation';
	import { Button } from '$lib/components/atoms/Button';
	import { Grid } from '$lib/components/organisms/Layout';
	import { page } from '$app/stores';
	import Search from '$lib/components/atoms/Icon/icons/Search.svelte';

  export let title = 'Cerca su 24 ore business school';
  export let placeholder = 'Cosa stai cercando?';
  export let bgColor: 'gray' | 'clear' = 'gray';
  export let compact = false;
  export let centered = false;
  export let isSearching = false;
  export let value = '';
  export let searchQueryAdditional = '';

  let timer;
  let partialTyped = value;

  let filterStoreInstance = FilterStore.getInstance();
  let filters = filterStoreInstance.filterStore;
  let filtersApplied = filterStoreInstance.filtersApplied;

  let searchStore = filterStoreInstance.searchStore;
  let currentTyped = filterStoreInstance.currentTyped;

  const dispatch = createEventDispatcher();

  onMount(() => {
    partialTyped = searchStore ? $searchStore : '';
  });

  const onFocus = () => {
    if (!isMobileBrowser()) {
      dispatch('focus');
    }
  };

  const onBlur = () => {
    if (document.hasFocus()) {
      dispatch('blur');
    }
  };

  const goSearch = async (e: Event) => {
    e.preventDefault();
    value = partialTyped;
    searchStore.set(value);

    let baseUrl = '/esplora';
    const searchParams = $page.url.searchParams;

    if ($searchStore !== '') {
      searchParams.set('q', $searchStore);
    } else {
      searchParams.set('q', 'matchall');
    }

    // reset filters if the search is launched from another page
    if ($page.route.id !== '/(app)/esplora') {
      filterStoreInstance.resetFilters();
    }

    for (let filter in $filters) {
      if ($filtersApplied[filter]) {
        if (Array.isArray($filters[filter])) {
          searchParams.set(filter, $filters[filter].join(';'));
        } else {
          searchParams.set(filter, $filters[filter]);
        }
      } else {
        searchParams.delete(filter);
      }
    }

    const queryString =
      searchParams.toString() !== '' ? '?' + searchParams.toString() : '';

    const url = baseUrl + queryString;

    await goto(url, { noScroll: true });
  };

  const debounce = (partialTyped) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      value = partialTyped;
      $currentTyped = value;
    }, 300);
  };
</script>

<div
  class="search-form-container"
  class:v-compact={compact}
  class:c-gray={bgColor === 'gray'}
>
  <Grid noGrid>
    <div class="search-form-wrapper" class:v-centered={centered}>
      <p class="subtitle search-form-title">{title}</p>
      <form
        class="search-form"
        on:submit|preventDefault={goSearch}
      >
        <input
          type="search"
          class="search-input"
          {placeholder}
          on:focus={onFocus}
          on:blur={onBlur}
          on:keyup={({ target: { value } }) => debounce(value)}
          bind:value={partialTyped}
        />
        <div class="search-submit">
          <Button
            isLoading={isSearching}
            disabled={isSearching}
            color="pink"
            type="submit"
          >
            Cerca
          </Button>
        </div>
      </form>
    </div>
  </Grid>
</div>

<style type="text/scss" lang="scss" scoped>
  .search-form-container {
    @apply flex-1 py-10 md:py-14;

    &.c-gray {
      @apply bg-gray-50;
    }

    &.v-compact {
      .search-form-title {
        @apply text-sm md:text-base;
      }

      .search-form {
        @apply mt-2.5 md:mt-3;
      }
    }
  }

  .search-form-wrapper {
    @apply max-w-3xl;

    &.v-centered {
      @apply mx-auto;
    }
  }

  .search-form {
    @apply flex items-center;
    @apply mt-6;
  }

  .search-form-title {
  }

  .search-input {
    @apply flex-grow relative;
    @apply bg-transparent;
    @apply border-0 border-b border-gray-600;
    @apply px-0 py-1 h-12;
    @apply transition-colors duration-200 ease-in;
    @apply text-xl font-medium font-serif;
    @apply md:text-5xl;

    &:focus {
      box-shadow: none;
      @apply outline-none border-brand;
    }

    &::-webkit-search-cancel-button {
      @apply appearance-none;
      @apply absolute right-2 top-1/2;
      @apply transform -translate-y-1/2;
      @apply w-4 h-4 cursor-pointer;
      @apply bg-center bg-no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath \20
      d='M12.595 4.202a.664.664 0 00-.94 0l-3.26 3.253-3.26-3.26a.664.664 0 \20
      10-.94.94l3.26 3.26-3.26 3.26a.664.664 0 10.94.94l3.26-3.26 3.26 3.26a.664.664 \20
      0 10.94-.94l-3.26-3.26 3.26-3.26a.668.668 0 000-.933z' fill='%23D3D3D3'/%3E%3C/svg%3E");
    }
  }

  .search-submit {
    @apply hidden md:grid;
    @apply ml-5;
  }
</style>
